export const ApiUrl = "https://lsapi.vps.zimnet.uk/api/";
export const StaticUrl = "https://lsapi.vps.zimnet.uk/";
export const PublicUrl = "https://lsapi.vps.zimnet.uk/storage/images/uploads/";
export const AssetsUrl = "https://lsapi.vps.zimnet.uk/storage/images/assets/";
export const ProfileUrl = "https://lsapi.vps.zimnet.uk/storage/images/profile/";
export const PhotosArchivesUrl = "https://lsapi.vps.zimnet.uk/storage/images_archives/";

export const makeImgUrl = (ref,size,filename) => {

    return PublicUrl + ref + "/" + size + '/' + filename;
}

export const makeProfileImgUrl = (user) => {
    return user ? ProfileUrl + user.id + "/" + user.photo : null;
}