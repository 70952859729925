import * as React from 'react';
import { useParams } from 'react-router-dom';
import { SaleForm } from '.';
import { DashboardContainer } from 'views/Dashboard/components';
import { useContext, useState } from 'react';
import { Box, Button, Alert, Card, CardContent, CardHeader, Typography, TextField} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { selectSaleById, saleUpdated } from 'features/sales/salesSlice';
import ContentContext from 'layouts/ContentContext'
import ScrollToContentTop from 'utils/ScrollToContentTop'
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import { CustomerDetailsTable, AddressDetailsTable, ProductsDetailsTable, ProductExtrasDetailsTable } from 'views/Dashboard/shared';
import { useSnackbar } from 'notistack';
import parseDate from 'utils/parseDate';
import { fetchProducts } from 'features/products/productsSlice';

export default function EditSaleForm(props){

    const contentDiv = useContext(ContentContext);

    console.log("SALE EDIT CONTEXT", contentDiv);

    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    console.log("PARAMS", params);
    const id = params.id

    const sale = useSelector((state) => selectSaleById(state, id));
    console.log("SALE SELECTOR: ", sale);
    const updateStatus = useSelector((state) => state.sales.editing.status);
    const updateError = useSelector((state) => state.sales.editing.error);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);

    const updateSale = s => {
        const payload = { action: "saleDetails", id: s.id, sale: s }
        setIsSaving(true);
        dispatch(saleUpdated(payload)).then(result => {
            if(result.type === "sales/saleUpdated/fulfilled"){
                
                setIsSaving(false);
                ScrollToContentTop(contentDiv);
                navigate("../../");
            }
            if(result.type === "sales/saleUpdated/rejected"){
                ScrollToContentTop(contentDiv);
            }
        });    
    }

    const handleProductDelete = (event, product) => {
        event.stopPropagation();
        const payload = { action: "productRemoved", id: sale.id, product_id: product.id }
        let confirm = false;
        if(sale.products.length > 1){
            confirm = window.confirm("Are you sure ?");
        } else {
            alert('Sale requires at least one product. To change the last product please create new invoice');
        }
    
        if(confirm) dispatch(saleUpdated(payload)).then(result => {
    
          if(result.type === "sales/saleUpdated/fulfilled"){
            enqueueSnackbar("Product removed from sale");
            // update product slice localy, sale updated on server

            dispatch(fetchProducts());
          }
          else{
            enqueueSnackbar("Sale - " + result.error.message);
          }
        });        
    }

    const handleExtraDelete = (event, extra) => {
        event.stopPropagation();
        const payload = { action: "extraRemoved", id: sale.id, extra_id: extra.id }
        let confirm = window.confirm("Are you sure ?");
    
        if(confirm) dispatch(saleUpdated(payload)).then(result => {
    
          if(result.type === "sales/saleUpdated/fulfilled"){
            enqueueSnackbar("Extra removed from sale");
          }
          else{
            enqueueSnackbar("Sale - " + result.error.message);
          }
        });        
    }

    const handleExtraAdd = (extra) => {

        const payload = { action: "extraAdded", id: sale.id, extra: extra }
    
        dispatch(saleUpdated(payload)).then(result => {
    
          if(result.type === "sales/saleUpdated/fulfilled"){
            enqueueSnackbar("Extra added to sale");
          }
          else{
            enqueueSnackbar("Sale - " + result.error.message);
          }
        });        
    }
    return(
        <>
        <DashboardContainer>
            
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 1
                }}
            >
                <Box>
                    <Link to="../../">
                        <Button
                        sx={{ mr: "auto" }}
                        color="success"
                        variant="outlined"
                        size="small" 
                        >
                        Back
                        </Button>
                    </Link>
                </Box>
                { sale &&
                <Box sx={{ display:"flex",columnGap: 1 }}>

                    <Link to={"printInvoice/" + sale.id }>
                        <Button sx={{ mx: 1 }} size="small" >
                        Print
                        </Button>
                    </Link>

                </Box>
                }
            </Box>
            <Box sx={{ width: '100%', mt:3 }}>
            
            { updateError && <Alert severity="error">{updateError}</Alert>}
            { isSaving && 
                <>
                <LinearProgress />
                <Alert severity="info">STATUS : {updateStatus}</Alert>
                </>
            }   
            { sale === undefined && <Alert severity="error">Sale with given ID does not exist.</Alert> }
            { sale &&
            <>
            <Alert variant="outlined" severity="success">DATE : { parseDate(sale.created_at) }</Alert>
            { sale.extras.length > 0 &&
                <Alert sx={{mt:2}} severity="info">There {sale.extras.length > 1 ? "are" : "is"} {sale.extras.length} extra product{sale.extras.length > 1 ? "s" : ""} added to this sale</Alert>
            }
            <Card sx={{mt:2}}>
                <CardContent>
                    <SaleForm 
                        editMode={true} 
                        model={sale}
                        onAction={updateSale}
                        submitLabel="Update"
                    />                   
                </CardContent>
            </Card>
           
            <Card sx={{mt:2}}>
                <CardContent>
                <Box sx={{ margin: 1,display:'flex',columnGap:2,rowGap:2,border:"1px solid #ddd", flexWrap:"wrap"}}>
         
            
                    <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"300px",width:{ "xs": "100%"}, backgroundColor:"#fafafa"}}>

                        <CustomerDetailsTable 
                            customer={sale.customers[0]} />

                    </Box>
                    
                    <Box sx={{ flexGrow:1,flexBasis:0, minWidth:"300px",width:{ "xs": "100%"}}}>

                        <AddressDetailsTable 
                            address={sale.customers[0].addresses[0]} 
                            type="Billing" />

                        <AddressDetailsTable 
                            address={sale.deliveries[0].addresses[0]} 
                            type="Delivery" />

                    </Box>
                </Box>
                </CardContent>
            </Card>
            <Card sx={{mt:2,mb:2}}>
                <CardContent>
                    <ProductsDetailsTable
                        products={sale.products}
                        onDelete={handleProductDelete}
                        withDrawer={true}
                        withActions={true}
                        rowColor="#F6FCEF"/>
                </CardContent>
            </Card>
            <Card sx={{mt:2,mb:2}}>
                <CardContent>
                    <ProductExtrasDetailsTable
                        extras={sale.extras}
                        onDelete={handleExtraDelete}
                        onAdd={handleExtraAdd}
                    />
                </CardContent>
            </Card>
            </>
            }

        </Box>
        </DashboardContainer>
        </>
    )

}

