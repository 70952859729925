import React from "react";
import usePageVisibility from "hooks/usePageVisibility";
import useInitFbSDK from "./useInitFbSDK";
import { Button } from "@mui/material";

const useFB= (pageID) => {
  
  const isFbSDKInitialized = useInitFbSDK();
  const isPageVisible = usePageVisibility();
  const [fbUserAccessToken, setFbUserAccessToken] = React.useState(null);
  const [fbPageAccessToken, setFbPageAccessToken] = React.useState(null);
  const [isPublishing, setIsPublishing] = React.useState(false);

  React.useEffect(() => {
    if (isFbSDKInitialized && isPageVisible) {
      
      window.FB.getLoginStatus((response) => {
        console.log("GETTING FB LOGIN STATUS:", response);
        if(response.status == 'connected') setFbUserAccessToken(response.authResponse.accessToken);
        else {
          console.log('TOKEN EXPIRED. USER LOOGED OUT OF FACEBOOK');
          setFbPageAccessToken(null);
          setFbUserAccessToken(null);
        }
      });
    } else {
      setFbPageAccessToken(null);
      setFbUserAccessToken(null);
    }
  }, [isFbSDKInitialized, isPageVisible]);

  React.useEffect(() => {
    if (isFbSDKInitialized && fbUserAccessToken) {
      window.FB.api(
        `/${PAGE_ID}?fields=access_token&access_token=${fbUserAccessToken}`,
        (response) => {
          console.log('GETTING FB PAGE TOKEN:', response);
          if(response.error){
            logout();
            setFbPageAccessToken(null);
            setFbUserAccessToken(null);
          }
          else setFbPageAccessToken(response.access_token)
        }
      );
    }
  }, [fbUserAccessToken]);

  const PAGE_ID = pageID;

  const isUserLoggedIn = Boolean(fbUserAccessToken) && Boolean(fbPageAccessToken);

  // Logs in a Facebook user
  const login = () => {

    if(!isFbSDKInitialized) { console.log('Facebook not initialized'); return;}
    window.FB.login((response) => {
      setFbUserAccessToken(response.authResponse?.accessToken ? response.authResponse.accessToken : null);
    });
  };

  // Logs out the current Facebook user
  const logout = () => {
    if(!isFbSDKInitialized) { console.log('Facebook not initialized'); return;}
    window.FB.logout(() => {
      setFbUserAccessToken(null);
      setFbPageAccessToken(null);
    });
  };

  const postToPage = (postData, cb) => {

    if(fbPageAccessToken && !isPublishing){
      setIsPublishing(true);
       window.FB.api(
        `/${PAGE_ID}/feed`,
        "POST",
        {
          ...postData,
          access_token: fbPageAccessToken,
        },
        (response) => {
          setIsPublishing(false);
          if(response.error) cb({ status: 'error', payload: response.error}); 
          else cb({status: 'success', payload: response});
            
          return;
        }
      ); 
      /* setIsPublishing(false);
      let number = Math.floor(Math.random() * 10);
      if(number < 5) setTimeout(() => cb({status: 'error', payload: { msg: 'asdasd'}}),1000);
      else setTimeout(() => cb({status: 'success', payload: {id : number + '1237687649128474'}}),1000); */
    } else { console.log('Cannot post to page: missing token or posting in progress');}
  };

  const uploadPhotoToPage = (photo_url, cb) => {
    console.log('Uploading photo');
     if(fbPageAccessToken){
       window.FB.api(
        `/${PAGE_ID}/photos`,
        "POST",
        {
          url : photo_url,
          published: false,
          access_token: fbPageAccessToken,
        },
        (response) => {
          if(response.error) cb({ status: 'error', payload: response.error});
          else cb({status: 'success', payload: response})
          return;
        }
      );   
      /* let number = Math.floor(Math.random() * 10);
      if(number < 5) setTimeout(() => cb({status: 'error', payload: { msg: 'asdasd'}}),1000);
      else setTimeout(() => cb({status: 'success', payload: {id : number + '1237687649128474'}}),1000); */

    } else { console.log('Cannot post to page: missing token');}

    return;
  };

  const LoginButton = () => {
    return (
      <Button variant={'outlined'} onClick={isUserLoggedIn ? logout : login}>
        {isUserLoggedIn ? 'FB Logout' : 'FB Login'}
      </Button>
    )
  }

    return {isUserLoggedIn, login, logout, postToPage, uploadPhotoToPage, isPublishing, LoginButton}
};

export default useFB;