import React from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { DashboardContainer } from '../components';
import { Card, CardContent, Chip, Button, ButtonGroup, IconButton, Typography, Tooltip, Divider } from '@mui/material';
import { useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { ApiUrl } from 'features/api_url';
import { uniqueId } from 'underscore';
import { Box } from '@mui/system';
import Modal from 'components/Modal';

import { useDispatch, useSelector } from 'react-redux';
import { eventAdded, eventDeleted, eventUpdated } from 'features/events/eventsSlice';
import AddEventDialog from './components/AddEventDialog';
import { EventInfo } from './components/EventInfo';

const monthsNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];

const Events = () => {

    let calendarRef = React.useRef();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();

    const [weekendsVisible,setWeekendsVisible] = React.useState(true);
    const [currentEvents, setCurrentEvents] = React.useState([]);
    const [currentDate, setCurrentDate] = React.useState('');
    const [initialView, setInitialView] = React.useState('dayGridMonth');
    const [addEventOpen, setAddEventOpen] = React.useState(false);
    const [selectedAddDate, setSelectedAddDate] = React.useState('');
    const [showEventInfo, setShowEventInfo] = React.useState(false);
    const [activeEventData, setActiveEventData] = React.useState(null);
    const abortCtrl = new AbortController();

    React.useEffect(() => {
  
      const getData = async () => {
        await axios.get(ApiUrl + 'events',{ signal: abortCtrl.signal})
          .then(response => {
  
            if(response.data) setCurrentEvents(response.data);
  
          })
          .catch((err) => console.log('ABORTING: ', err));
        
      }
  
      getData();

      const today = new Date();
      parseCurrentDate(today);
      
  
      return () => {
        console.log("UNMOUNTING delivery data:");
        abortCtrl.abort();
      }
  
    },[]);

    const parseCurrentDate = (cdate) => {

      const parsedDate = monthsNames[cdate.getMonth()] + " " + cdate.getFullYear();

      setCurrentDate(parsedDate);
      
      

    }

    console.log("RENDERING");

    const handleDateSelect = (selectInfo) => {

        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection
    
       setSelectedAddDate(selectInfo.startStr);
       setAddEventOpen(true);
      }
    
    const handleEventClick = (clickInfo) => {
        let eventData = {...clickInfo.event.extendedProps, id: clickInfo.event.id };
        console.log(eventData);
        setActiveEventData(eventData);
        setShowEventInfo(true);
    }

    const handleChangeEvent = (eventInfo) => {
      console.log("CHANGEED EVENT")
      console.log(eventInfo);

      dispatch(eventUpdated({id : eventInfo.event.id, new_date: eventInfo.event.startStr }))
        .then(result => {
  
            if(result.type === "events/eventUpdated/fulfilled"){
              console.log("SUCCESS EVENT UPDATE", result.payload);

            }
            else {console.log("FAIL EVENT UPDATE");}
        });
    };

    const handleRemoveEvent = (eventInfo) => {console.log("REMOVEED EVENT")};
    const handleAddEvent = (eventInfo) => {console.log("ADDED EVENT", eventInfo)};
    const calendarApi = () => calendarRef.current.getApi();

    const handleDeleteEvent = () => {
      if(activeEventData){
        let event = calendarApi().getEventById(activeEventData.id);
        if(event){
          if (window.confirm(`Are you sure you want to delete the event '${event.title}'`)) {

            dispatch(eventDeleted({id: activeEventData.id}))
              .then(result => {

                if(result.type === "events/eventDeleted/fulfilled"){
                  setShowEventInfo(false);
                  setActiveEventData(null);
                  event.remove();

              }
              else {
                alert('Failed to delete the event.');
            }
            });

          }
        }
      }
    }

    const changeView = (view) => {
        
        calendarApi().changeView(view);
        
    }
    const goBack = () => {

      calendarApi().prev();
      const cd = calendarApi().getDate();
      parseCurrentDate(cd);
    }
    const goForward = () => { 

      calendarApi().next();
      const cd = calendarApi().getDate();
      parseCurrentDate(cd);
    }

    const moveToToday = () => {

      calendarApi().today();
      const cd = calendarApi().getDate();
      parseCurrentDate(cd);
    }

    const addEventHandleClose = () => setAddEventOpen(false);

    const addNewEvent = () => {
      setAddEventOpen(true);
    }

    return (
        <DashboardContainer>
            <Card>
                <CardContent>
                    <Box sx={{display:'flex', justifyContent:'space-between'}}>
                        <ButtonGroup variant="outlined" aria-label="prev next button">
                          <Tooltip title="Go Back">
                            <Button onClick={goBack}><ArrowBackIosOutlinedIcon /></Button>
                          </Tooltip>
                          <Tooltip title="Go Forward">
                            <Button onClick={goForward}><ArrowForwardIosOutlinedIcon /></Button>
                          </Tooltip>
                          <Tooltip title="Add New event">
                            <Button onClick={addNewEvent}><AddIcon /></Button>
                          </Tooltip>
                        </ButtonGroup>
                        <Typography variant="h4">{ currentDate }</Typography>
                    </Box>
                    <Divider sx={{marginY:1}}/>
                    <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'flex-end', columnGap:1,marginY:1}}>
                        <Tooltip title="View month List">
                          <Button variant={'outlined'} sx={{paddingX:0}} onClick={() => changeView('listMonth')}><TableRowsIcon /></Button>
                        </Tooltip>
                        <Tooltip title="View month grid">
                          <Button variant={'outlined'} onClick={() => changeView('dayGridMonth')}><ViewComfyIcon /></Button>
                        </Tooltip>
                        <Tooltip title="View week grid">
                        <Button variant={'outlined'} onClick={() => changeView('timeGridWeek')}><ViewWeekOutlinedIcon /></Button>
                          </Tooltip>
                        <Tooltip title="Go to current date">
                          <Button variant={'outlined'} onClick={moveToToday}><TodayOutlinedIcon /></Button>
                        </Tooltip>

                    </Box>
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        initialView={initialView}
                        headerToolbar={false}
                        editable={true}
                        dayMaxEvents={10}
                        selectable={true}
                        selectMirror={true}
                        weekends={weekendsVisible}
                        events={currentEvents}
                        select={handleDateSelect}
                        eventClick={handleEventClick}
                        //eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                        /* you can update a remote database when these fire: */
                        eventAdd={handleAddEvent}
                        eventChange={handleChangeEvent}
                        eventRemove={handleRemoveEvent}
                       
                    />
                    <Box sx={{display:'flex', columnGap:1, justifyContent:'flex-start', flexWrap: 'wrap',mt:3}}>
                      <Box><FiberManualRecordIcon sx={{ color: 'blue',fontSize:"12px",mr:1 }}  />{' '}Delivery</Box>
                      <Box><FiberManualRecordIcon sx={{ color: 'green',fontSize:"12px",mr:1 }}  />{' '}Collection</Box>
                      <Box><FiberManualRecordIcon sx={{ color: 'purple',fontSize:"12px",mr:1 }}  />{' '}Reminder</Box>
                      <Box><FiberManualRecordIcon sx={{ color: 'red',fontSize:"12px",mr:1 }}  />{' '}Unknown</Box>

                    </Box>
                </CardContent>
            </Card>
            
            <AddEventDialog 
              open={addEventOpen} 
              selectedDate={selectedAddDate} 
              handleClose={addEventHandleClose} 
              calendar={calendarApi}
            />
            <Modal
              open={showEventInfo}
              onClose={() => { setShowEventInfo(false); setActiveEventData(null);}}
              title={'Event information'}
            >
              <Box>
                <EventInfo eventData={activeEventData} onDelete={handleDeleteEvent} />
              </Box>
            </Modal>
        </DashboardContainer>
    )
  }

  export default Events;