import React from 'react';
import { Box,Button, Alert, Typography, Table, TableRow, TableCell, TableBody, LinearProgress } from '@mui/material';
import FBContext from 'components/facebook/FBContext';

const PostProductOnFacebook = (props) => {

    const { product } = props;
    const [FbUploadPhotos, setFbUploadPhotos] = React.useState([]);
    const [FbUploadInit, setFbUploadInit] = React.useState(false);
    const [FbPostStatus, setFbPostStatus] = React.useState(null);

    const FB = React.useContext(FBContext);


    const createPhotosArray = () => {
        let image_urls = [];
        if(product && product.images && product.images.length > 0){
            product.images.forEach(image => {
                image_urls.push(
                    { url : "https://api.lincolnshirestatics.com/storage/images/uploads/" + product.id + "/1024/" + image.url, 
                        order:image.order, 
                        id: image.id, 
                        upload_id: null,
                        done:false }
                );
            });
            setFbUploadPhotos(image_urls);
        }
    }

    React.useEffect(()=> {
        console.log('Updated fb upload photos');
        createPhotosArray();
    },[product]);
  
    const uploadedPhotosSuccessCount = React.useMemo(
        () => { 
            return FbUploadPhotos 
                ? FbUploadPhotos.reduce((acc,photo) => { return photo.upload_id ? acc+1 : acc },0)
                : null;
        },
        [FbUploadPhotos]
    );
    
    const uploadedPhotosDoneCount = React.useMemo(
        () => { 
            return FbUploadPhotos 
                ? FbUploadPhotos.reduce((acc,photo) => { return photo.done ? acc+1 : acc },0)
                : null;
        },
        [FbUploadPhotos]
    );

    const postHasPhotos = FbUploadPhotos.length > 0;
    const allPhotosUploaded = uploadedPhotosSuccessCount == FbUploadPhotos.length;
    const uploadFailed = (uploadedPhotosDoneCount == FbUploadPhotos.length) && (uploadedPhotosSuccessCount != FbUploadPhotos.length)

    // Curried function callback for FB photo upload
    const photoCallback = (photo) => (response) => {
        console.log("Processing photo " + photo.id);
        setFbUploadPhotos((currentState) => {
            return currentState.map(item => 
                {
                    if (item.id == photo.id){
                        return {
                            ...item, 
                            upload_id: response.status === 'success' ? response.payload.id : null,
                            done:true
                        };
                    }
                    return { ...item };
                });
        });

    }

    const postCallback = (product) => (response) => {
        console.log("POST RESPONSE: ", response);
        setFbPostStatus(response);
        if(response.status == 'success'){
            setFbUploadInit(false);
            createPhotosArray();
        }

    }

    const retryFailedPhotosUpload = () => {

        let failedUploads = FbUploadPhotos.filter((photo) => photo.upload_id == null);
        console.log("Failed items: ", failedUploads);
        failedUploads.forEach(image => {
            FB.uploadPhotoToPage(image.url, photoCallback(image));
        });
    }
    const postProductToFacebook = () => {

        setFbPostStatus(null);
        let postData = { message: product.extra_info };

        // if photos present, attach to post
        if(FbUploadPhotos && allPhotosUploaded){

            let photos_array = FbUploadPhotos.map((photo) => {
                return {"media_fbid": photo.upload_id};
            });
            postData.attached_media = photos_array;
        };

        console.log("POST DATA: ", postData);
        FB.postToPage(postData, postCallback(product));

    };
    const uploadPhotosToFacebook = () => {
        setFbUploadInit(true);

        console.log(product);
        if(FbUploadPhotos && FbUploadPhotos.length > 0){
            FbUploadPhotos.forEach(image => {

                FB.uploadPhotoToPage(image.url, photoCallback(image));
            });
        }


        return;

    }

    const normaliseProgress = (value, max) => ((value - 0) * 100) / (max - 0);

    return (
        <Box>
            <Box sx={{display:'flex', justifyContent: 'space-between'}}>
                <Typography variant="h4" sx={{mb:2}}>Facebook Page</Typography>
                { FB.LoginButton() }
            </Box>
            
            {FB.isUserLoggedIn ? (  
            <>
                

                { postHasPhotos &&
                    <>
                        { allPhotosUploaded ? (
                        <Alert severity={'success'} variant={'outlined'} sx={{marginY:1}}>Photos uploaded successfully</Alert>
                        ) :(
                        <Alert severity={'info'} variant={'outlined'} sx={{marginY:1}}>To include photos with this post, please upload them first</Alert>
                        )
                        }
                        {uploadFailed &&
                        <Alert severity={'error'} variant={'outlined'} sx={{marginY:1}}>Some photos failed to upload. Please retry.</Alert>
                        }
                        <Box sx={{display:'flex', columnGap:1, flexDirection:'row', alignItems:'center',mb:1}}>
                            <Button variant={'outlined'} onClick={uploadPhotosToFacebook} disabled={FbUploadInit}>
                                UPLOAD
                            </Button>

                            <Typography variant={'caption'}>Processesed: {uploadedPhotosDoneCount} of {FbUploadPhotos.length } Uploaded: {uploadedPhotosSuccessCount}</Typography>

                            {uploadFailed &&
                                <Button variant={'outlined'} onClick={retryFailedPhotosUpload}>Retry</Button>
                            }
 
                        </Box>

                    </>
                }
                <Box>
                    <Button 
                        sx={{mb:1}}
                        variant={'outlined'} 
                        onClick={postProductToFacebook}
                        disabled={FB.isPublishing}
                    >
                        POST ON FACEBOOK
                    </Button>
                    {FB.isPublishing && <LinearProgress /> }
                    
                </Box>
                <Box sx={{mt:1}}>
                    { FbPostStatus &&
                    <>
                        { FbPostStatus.status == 'success' ? (
                            <Alert variant={'filled'} severity={'success'}>Post published on facebook</Alert>
                        ) : (
                            <Alert variant={'filled'} severity={'error'}>Post failed to publish. Try again</Alert>
                        )
                        }
                    </>
                    }
                </Box>
            </>
            ) : (
                <Alert severity={'info'} sx={{mt:1}}>You need to login to facebook to post this product</Alert>
            )
            }
        </Box>
    );
}

export default PostProductOnFacebook;