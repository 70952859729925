import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from '../api_url'
import axios from 'axios'

import { nullToString } from 'utils/nullToString';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  value: 0,
  editing: { status:'idle', error: null},
  creating: { status:'idle', error: null},
  deleting: { status: 'idle', error: null},
  uploading: { status: 'idle', error: null},
  downloading: { status: 'idle', error: null},
  removingPhoto: { status: 'idle', error: null}
}


export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {

  const response = await axios.get(ApiUrl + "products");
  return response.data

})

export const productAdded = createAsyncThunk('products/productAdded', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.post(ApiUrl + "products", payload);
  return response.data

})

export const productUpdated = createAsyncThunk('products/productUpdated', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.put(ApiUrl + "products/" + payload.id, payload);
  return response.data

})

export const productDeleted = createAsyncThunk('products/productDeleted', async (payload, thunkAPI) => {

  console.log(payload);
  const response = await axios.delete(ApiUrl + "products/" + payload.id, payload);
  return response.data

})

export const productUploadedPhotos = createAsyncThunk('product/uploadPhotos', async (payload, thunkAPI) => {

  console.log(payload);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  const response = await axios.post( ApiUrl + "products/uploadPhotos", payload,
  config,
)
  return response.data

})

export const productRemovedPhoto = createAsyncThunk('product/removedPhoto', async (payload, thunkAPI) => {

  const response = await axios.post( ApiUrl + "products/removePhoto", payload);

  return response.data

})

export const productDownloadedPhotos = createAsyncThunk('products/downloadPhotos', async (payload, thunkAPI) => {

  const response = await axios.post(ApiUrl + "products/downloadPhotos", payload);
  return response.data

})

export const checkIfPhotosZipExists = createAsyncThunk('products/checkIfPhotosZipExists', async (payload, thunkAPI) => {

  const response = await axios.post(ApiUrl + "products/checkIfPhotosZipExists", payload);
  return response.data

})

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productsReload: (state) => {
      state.status = "idle"
    },
    productUnsold: (state, action) => {
      console.log("PRODUCT ID: ", action)
      const index = state.items.findIndex(function(o){
        return o.id == action.payload.id;
      })
      state.items[index].sale_id = null;
    },
    productRemoveFromStore: (state, action) => {
      const index = state.items.findIndex(function(o){
        return o.id == action.payload.id;
      })

      state.items.splice(index,1);
          
    },
    productAddToStore: (state, action) => {
      
      state.items.push(action.payload);
          
    },
    reset: state => initialState
  },
  extraReducers(builder) {
      builder
        // FETCH STOCKINS
        .addCase(fetchProducts.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchProducts.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.error = null;
          // Add any fetched Products to the array
          state.items = [];
          state.items = state.items.concat(action.payload);
         
        })
        .addCase(fetchProducts.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        // CREATE Product
        .addCase(productAdded.pending, (state, action) => {
          state.creating.status = 'inprogress';
        })
        .addCase(productAdded.fulfilled, (state, action) => {
          state.creating.status = 'succeeded';
          state.creating.error = null;
          // Add any fetched Products to the array
          state.items.push(action.payload);
        })
        .addCase(productAdded.rejected, (state, action) => {
          state.creating.status = 'failed';
          state.creating.error = action.error.message;
        })
        // UPDATE Product
        .addCase(productUpdated.pending, (state, action) => {
          state.editing.status = 'pending';
        })
        .addCase(productUpdated.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.editing.status = 'success';
          // state.items.push(action.payload)
        })
        .addCase(productUpdated.rejected, (state, action) => {
          state.editing.status = 'failed';
          state.editing.error = action.error.message;
        })

        // DELETE PRODUCT
        .addCase(productDeleted.pending, (state, action) => {
          state.deleting.status = 'pending';
        })
        .addCase(productDeleted.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items.splice(index,1);
          state.deleting.status = 'success';
          
        })
        .addCase(productDeleted.rejected, (state, action) => {
          state.deleting.status = 'failed';
          state.deleting.error = action.error.message;
        })
        // UPLOAD PHOTOS PRODUCT
        .addCase(productUploadedPhotos.pending, (state, action) => {
          state.uploading.status = 'pending';
        })
        .addCase(productUploadedPhotos.fulfilled, (state, action) => {
          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })
          console.log("Photo upload index ", index);
          console.log(action.payload);
          state.items[index] = action.payload;
          state.uploading.status = 'success';
          
        })
        .addCase(productUploadedPhotos.rejected, (state, action) => {
          state.uploading.status = 'failed';
          state.uploading.error = action.error.message;
        })
        // DOWNLOAD PHOTOS PRODUCT
        .addCase(productDownloadedPhotos.pending, (state, action) => {
          state.downloading.status = 'pending';
        })
        .addCase(productDownloadedPhotos.fulfilled, (state, action) => {
          console.log("Download ", action);
          state.downloading.status = 'success';
          
        })
        .addCase(productDownloadedPhotos.rejected, (state, action) => {
          state.downloading.status = 'failed';
          state.downloading.error = action.error.message;
        })
        // Remove photo from product
        .addCase(productRemovedPhoto.pending, (state, action) => {
          state.removingPhoto.status = 'pending';
        })
        .addCase(productRemovedPhoto.fulfilled, (state, action) => {

          const index = state.items.findIndex(function(o){
            return o.id == action.payload.id;
          })

          state.items[index] = action.payload;
          state.removingPhoto.status = 'success';
        })
        .addCase(productRemovedPhoto.rejected, (state, action) => {
          state.removingPhoto.status = 'failed';
          state.removingPhoto.error = action.error.message;
        })
  }
})

export const { productUnsold, productAddToStore, productRemoveFromStore, productsReload, reset } = productsSlice.actions;

export const selectNextProductId = state => {
    
    if(state.products.items.length > 0) {
      const product = state.products.items.reduce((p, c) => parseInt(p.id) < parseInt(c.id) ? c : p);

      return product.id + 1;
    }
    else return 0;
}

export const selectProductById = (state, productId) => {

  let foundItem = null;
  if(state.products.items.length > 0) {

    foundItem = state.products.items.find(product => product.id == productId);

    foundItem = foundItem ? nullToString(foundItem) : undefined;
    
  }
  return foundItem;
}

export const selectAllProducts = state => state.products.items;
export const selectProductsCount = state => state.products.items.length;
export const selectProductsAvailable = state => state.products.items.filter(item => item.sale_id === null);
export const selectProductsSold = state => state.products.items.filter(item => item.sale_id !== null);
export const selectProductsAvailableCount = state => state.products.items.filter(item => item.sale_id === null).length;
export const selectProductsSoldCount = state => state.products.items.filter(item => item.sale_id !== null).length;

export const selectProductsToPrint = (state, id) => {
  
  switch (id) {
    case 'all':
      
      return selectAllProducts(state);

    case 'available':

      return selectProductsAvailable(state);

    case 'sold':

    return selectProductsSold(state);


    default:
      return undefined;
  }

}

export const selectProductsTotal = state => state.products.items.reduce((prev,next) => prev + next.price,0);
export const selectProductsSoldTotal = state => 
                      state.products.items.reduce((prev,next) => next.sale_id !== null ? prev + next.price : prev,0);
export const selectProductsAvailableTotal = state => 
                      state.products.items.reduce((prev,next) => next.sale_id === null ? prev + next.price : prev,0);

export const selectProductsById = (state, productId) =>
                      state.products.items.find(product => product.id === productId)


export const selectLatestProducts = state => state.products.items.slice().sort((a,b) => b.id - a.id).slice(0,5);

export default productsSlice.reducer;