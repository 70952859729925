import * as React from 'react';
import {
    TableBody,
    TableContainer,
    TablePagination,
    Table,
    Alert,
    Box,
    Typography
} from "@mui/material"
import EnhancedEntitiesTableHead from './EnhancedEntitiesTableHead';
import EntitiesTableRows from './EntitiesTableRows';
import { getComparator, stableSort } from 'utils/sorting';

const EntitiesTable = (props) => {

  const { items, 
          columns, 
          entityName,
          rowActions,
          withDrawer,
          renderDrawer,
          rowStyles,
          onPick,
          orderColumn,
          selectable,
          maxSelected,
          singleSelect,
          onSelectedRows

           } = props;


  
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(orderColumn ? orderColumn : 'id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    if(onSelectedRows) onSelectedRows(selectedRows);
  },[selectedRows]);

  React.useEffect(() => {
    setSelectedRows([]);
  },[items]);

  const sortedItems = stableSort(items, getComparator(order, orderBy));

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedItems.length) : 0;
  const pageRows = sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  console.log("Selected rows", selectedRows);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleAllSelected = (event) => {
    if (event.target.checked) {
      const newSelecteds = sortedItems.map((n) => n);
      setSelectedRows(!maxSelected ? newSelecteds : newSelecteds.slice(0, maxSelected));
      return;
    }
    setSelectedRows([]);
  };

  const toggleSelected = (event, entity) => {

    if(singleSelect) {
      setSelectedRows([entity]);
      return;
    }

    if(maxSelected && selectedRows.length >= maxSelected && event.target.checked) return;

    let newSelected = [];
    const selectedIndex = selectedRows.findIndex((e) => entity.id == e.id);

    if (selectedIndex > -1) {
      newSelected = selectedRows.slice();
      newSelected.splice(selectedIndex, 1);
    }
    else newSelected = newSelected.concat(selectedRows, entity); 

    setSelectedRows(newSelected);

  };

  const isSelected = (entity) => Boolean(selectedRows.find((e) => entity.id == e.id));

    

    return(
        <>
        { sortedItems.length === 0 ? 
        
        (<Alert severity="info">No {entityName} found.</Alert>
        ) : (
        <>
        
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Show:"
          />
          {selectable && selectedRows.length > 0 &&
            <Box sx={{display:'flex', justifyContent:'flex-start', ml:2}}>
              
              <Typography variant={'pgreen'}>
                Selected : {selectedRows.length } { maxSelected ? <Typography variant={'pred'}>(Max: {maxSelected > sortedItems.length ? sortedItems.length : maxSelected})</Typography> : '' }
              </Typography>
            </Box>
          }
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
            >
              <EnhancedEntitiesTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={sortedItems.length}
                columns={columns}
                rowActions={rowActions}
                withDrawer={withDrawer}
                onPick={onPick}
                onToggleAllSelected={toggleAllSelected}
                numSelected={selectedRows.length}
                selected={selectedRows}
                maxSelected={maxSelected}
                selectable={selectable}
                
              />
              <TableBody>
                
                <EntitiesTableRows 
                    rows={pageRows} 
                    rowActions={rowActions} 
                    columns={columns} 
                    withDrawer={withDrawer} 
                    renderDrawer={renderDrawer}
                    rowStyles={rowStyles}
                    onPick={onPick}
                    selectable={selectable}
                    isSelected={isSelected}
                    toggleSelected={toggleSelected}

                    />
               
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Show:"
          />
          </>
         )
        }
        </>
    )
}

export default EntitiesTable;