import * as React from 'react';
import {
  TextField,
  Grid,
  Box,
  Button,
  ToggleButton as MuiToggleButton,
  IconButton

} from '@mui/material';

import { SearchField } from 'views/Dashboard/shared';
import { searchObjects } from 'utils/searchObjects';
import { Close } from '@mui/icons-material';


const AvailibilityOptions = [{ value:'All', label: 'All'}, { value: 'Available', label: 'Available'}, { value: 'Sold', label: 'Sold'}];

const ProductsFilters = (props) => {

    const {
        items,
        onChange,
        
    } = props;

    const [search, setSearch] = React.useState("");
    const [availability, setAvailability] = React.useState("Available");
    const [price, setPrice] = React.useState({price_from:'',price_to:''});
    const [productFilters, setProductFilters] = React.useState({
    
      bed2: false,
      bed3 : false,
      dg: false,
      ch: false,
      ft10: false,
      ft12: false
  
    })

    React.useEffect(() => {
        console.log("RENDERING FILTERS");
        onFilterItems();
    },[items, search, productFilters, availability, price]);

    const filterAvailability = (list) => {
        console.log("Filtering availability...");
        return list.filter((item) => {
    
          if(availability === "Available"){
    
              return isAvailable(item);
    
          }
          if(availability === "Sold"){
    
            return !isAvailable(item);
    
          }
          if(availability === "All"){
            return true;
          }
    
          return true;
      });
    }

    const handleAvailabilityChange = (event) => {

        setAvailability(event.target.value);
        //setPage(0);
  
    }

    const handlePriceRangeChange = (event) => {

        const new_range = {...price, [event.target.name] : event.target.value};
        console.log(event.target.name + " - -" + event.target.value);
        setPrice(new_range);
        //setAvailability(event.target.value);
        //setPage(0);
  
    }
    const onFilterPriceRange = () => {

        if((price.price_from == '' && price.price_to) ||  price.price_from == price.price_to){
            console.log('Empty prices or the same');
            return;
        }
        onFilterItems();
    }

    const handleSearch = (value) => setSearch(value);
    const handleFilterChange = (filter) => setProductFilters({...productFilters, [filter] : !productFilters[filter]});

    const filterSearch = (list) => searchObjects(list, search);
    const filterBeds = (list) => {
       return list.filter(item => {
        if(productFilters.bed2 && productFilters.bed3 ) return true;
        if(productFilters.bed3) return item.beds == 3;
        if(productFilters.bed2) return item.beds == 2;
        return true;
      })
    }
    
    const filterDg = (list) => list.filter((item) => item.dg == true);
    const filterCh = (list) => list.filter((item) => item.ch == true);
    
    const filterWidth = (list) => {
        return list.filter(item => {
          if(productFilters.ft10 && productFilters.ft12 ) return true;
          if(productFilters.ft10) return item.width == 10;
          if(productFilters.ft12) return item.width == 12;
          return true;
        }) 
    }

    const filterPriceRange = (list) => {

        const price_from = price.price_from != '' ? price.price_from : 0;
        const price_to = price.price_to != '' ? price.price_to : 100000000;

        if((price_from == 0 && price_to == 100000000)) {
            console.log('FILTER Skipping price sorting');
            return list;
        }
        console.log("Filtering by price");
        return list.filter(item => {

            const itemPrice = parseFloat(item.price);
            return  itemPrice >= price_from && itemPrice <= price_to ? true : false; 
        });
    }

    const isAvailable = (item) => item.sale_id === null;

    const onFilterItems = () => {
        
        let filteredItems = items;

        filteredItems = filterAvailability(filteredItems);
        filteredItems = filterPriceRange(filteredItems);

        if(productFilters.bed2 || productFilters.bed3) filteredItems = filterBeds(filteredItems);
        if(productFilters.ft10 || productFilters.ft12) filteredItems = filterWidth(filteredItems);
        if(productFilters.dg) filteredItems = filterDg(filteredItems);
        if(productFilters.ch) filteredItems = filterCh(filteredItems);
        filteredItems = filterSearch(filteredItems);
        onChange(filteredItems);

    }

    return (
            <Grid
            container
            spacing={3}
            >
            <Grid
            item
            md={2}
            xs={12}
            >
                <SearchField onChange={handleSearch} />
            </Grid>
            <Grid
                item
                md={2}
                xs={12}
            >
                <TextField
                fullWidth
                label="Show"
                name="availability"
                value={availability}
                onChange={handleAvailabilityChange}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                >
                {AvailibilityOptions.map((option) => (
                    <option
                    key={option.value}
                    value={option.value}
                    >
                    {option.label}
                    </option>
                ))}
                </TextField>
                
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
            >
            <Box display={'flex'} sx={{ columnGap:1}}>
                <TextField
                    fullWidth
                    label="Price from"
                    type="number"
                    name="price_from"
                    InputLabelProps={{ shrink: true }}
                    value={price.price_from}
                    onChange={handlePriceRangeChange}
                    variant="outlined"

                />
                <TextField
                    fullWidth
                    label="Price to"
                    type="number"
                    name="price_to"
                    InputLabelProps={{ shrink: true }}
                    value={price.price_to}
                    onChange={handlePriceRangeChange}
                    variant="outlined"

                />
                <IconButton
                    onClick={() => setPrice({price_from:'', price_to:''})}
                >
                    <Close />
                </IconButton>

            </Box>
                
            </Grid>       
            <Grid
                item
                md={5}
                xs={12}
                sx={{ display:"flex", justifyContent:'flex-end', columnGap:1}}
            >
                
                <MuiToggleButton
                    value="check"
                    selected={productFilters.dg}
                    onChange={() => { handleFilterChange("dg")}}
                >
                    DG
                </MuiToggleButton>
                <MuiToggleButton
                    value="check"
                    selected={productFilters.ch}
                    onChange={() => { handleFilterChange("ch") }}
                >
                    CH
                </MuiToggleButton>
                <MuiToggleButton
                    value="check"
                    selected={productFilters.bed2}
                    onChange={() => { handleFilterChange("bed2")}}
                >
                    2B
                </MuiToggleButton>
                <MuiToggleButton
                    value="check"
                    selected={productFilters.bed3}
                    onChange={() => { handleFilterChange("bed3")}}
                >
                    3B
                </MuiToggleButton>
                <MuiToggleButton
                    value="check"
                    selected={productFilters.ft10}
                    onChange={() => { handleFilterChange("ft10")}}
                >
                    10
                </MuiToggleButton>
                <MuiToggleButton
                    value="check"
                    selected={productFilters.ft12}
                    onChange={() => { handleFilterChange("ft12")}}
                >
                    12
                </MuiToggleButton>
                
            </Grid>

        </Grid>

    )

}

export default ProductsFilters;