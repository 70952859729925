import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchStockins } from 'features/stockins/stockinsSlice';
import { fetchProducts } from 'features/products/productsSlice';
import { fetchSales } from 'features/sales/salesSlice';
import { fetchCustomers } from 'features/customers/customersSlice';
import { fetchAddresses } from 'features/addresses/addressesSlice';
import { fetchUsers } from 'features/users/usersSlice';
import { fetchRoles } from 'features/roles/rolesSlice';
import { LinearProgress } from '@mui/material';
import { fetchLoginHistory } from 'features/login_history/loginHistorySlice';
import { fetchSalesYears } from 'features/statistics/statisticsSlice';
import { fetchExpenses } from 'features/expenses/expensesSlice';
import { fetchEnquires } from 'features/enquires/enquiresSlice';
import { fetchSubscriptions } from 'features/subscriptions/subscriptionsSlice';
import { checkRole } from 'utils/checkRole';
import { selectUser } from 'features/users/usersSlice';
import { DashboardContainer } from '../components';


const LoadData = () => {

  const dispatch = useDispatch();

  const StockinsStatus = useSelector(state => state.stockins.status);
  const ProductsStatus = useSelector(state => state.products.status);
  const SalesStatus = useSelector(state => state.sales.status);
  const CustomersStatus = useSelector(state => state.customers.status);
  const AddressesStatus = useSelector(state => state.addresses.status);
  const UsersStatus = useSelector(state => state.users.status);
  const RolesStatus = useSelector(state => state.roles.status);
  const LoginHistoryStatus = useSelector(state => state.loginHistory.status);
  const StatisticsStatus = useSelector(state => state.statistics.status);
  const ExpensesStatus = useSelector(state => state.expenses.status);
  const EnquiresStatus = useSelector(state => state.enquires.status);
  const SubscriptionsStatus = useSelector(state => state.subscriptions.status);
  const user = useSelector(selectUser);

  const isTrue = (status) => status === "succeeded" ? true : false;
  
  const loadedStatus = isTrue(StockinsStatus) 
                        && isTrue(SalesStatus) 
                        && isTrue(ProductsStatus) 
                        && isTrue(CustomersStatus) 
                        && isTrue(AddressesStatus);
 
  
  useEffect(() => {

      if (StockinsStatus === 'idle') {

        dispatch(fetchStockins());
      }
      if (ProductsStatus === 'idle'){
        dispatch(fetchProducts());
      }
      if (SalesStatus === 'idle'){
        dispatch(fetchSales());
      }
      if (CustomersStatus === 'idle'){
        dispatch(fetchCustomers());
      }
      if (AddressesStatus === 'idle'){
        dispatch(fetchAddresses());
      }
      if (StatisticsStatus === 'idle'){
        dispatch(fetchSalesYears());
      }
      if (UsersStatus === 'idle'){
        if(checkRole(user, "administrator")) dispatch(fetchUsers());
        
      }
      if (RolesStatus === 'idle'){
        if(checkRole(user, "administrator")) dispatch(fetchRoles());
      }
      if (LoginHistoryStatus === 'idle'){
        if(checkRole(user, "administrator")) dispatch(fetchLoginHistory());
      }
      if (ExpensesStatus === 'idle'){
        if(checkRole(user, "administrator")) dispatch(fetchExpenses());
      }
      if (EnquiresStatus === 'idle'){
        if(checkRole(user, "administrator")) dispatch(fetchEnquires());
      }
      if (SubscriptionsStatus === 'idle'){
        if(checkRole(user, "administrator")) dispatch(fetchSubscriptions());
      }
  }, [StockinsStatus, 
      ProductsStatus, 
      SalesStatus, 
      CustomersStatus, 
      AddressesStatus,
      UsersStatus,
      RolesStatus,
      LoginHistoryStatus,
      StatisticsStatus, 
      ExpensesStatus,
      EnquiresStatus,
      SubscriptionsStatus,
      dispatch]);

    return (
      <DashboardContainer>
        { loadedStatus ?  <></> : <LinearProgress /> }
      </DashboardContainer>
    )
}

export default LoadData;
